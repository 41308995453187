import React from "react"
import { Link } from "gatsby"

import NavItem from "./navItem"

const CTANavItem = props =>
  <div class="cta-nav-item" onClick={props.onCTAClicked}>{props.children}</div>

const NavItems = props =>
  <div class="nav-items" data-visible={props.areVisible}>
    <div class="nav-items-background"></div>
    {props.items.map(item =>
      <NavItem
        key={item.section}
        section={item.section}
        page={item.page}
        onNavItemClicked={props.onNavItemClicked}
        active={item.section === props.activeSection}
      >
        {item.title}
      </NavItem>
    )}
    <CTANavItem onCTAClicked={props.onCTAClicked}>Contact me</CTANavItem>
  </div>

export default NavItems
