import React from "react"
import { Link } from "gatsby"

import NavItems from "./navItems"

const CTANavItem = props =>
  <div class="cta-nav-item" onClick={props.onCTAClicked}>{props.children}</div>

// TODO title and logo from graphql
// TODO create navItems dynamically by sections

const BurgerMenu = props =>
  <div class="burger-nav-menu" data-expanded={props.areNavItemsVisible} onClick={props.onClick}></div>

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHeaderFixed: false,
      burgerMenuVisible: false,
      navItemsVisible: false
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('scroll', this.handleResize);
  }

  handleScroll = event => {
    const scrollTop = event.srcElement.documentElement.scrollTop;
    this.setState({ isHeaderFixed: scrollTop > 150 });
  }

  handleResize = event => {
    this.setState({
      burgerMenuVisible: window.innerWidth <= 768,
      navItemsVisible: window.innerWidth > 768
    });
  }

  handleNavItemClick = event => {
    this.props.onNavItemClicked(event);
    if (this.state.burgerMenuVisible) {
      this.setState({ navItemsVisible: false });
    }
  }

  toggleNavItems = () => {
    this.setState({ navItemsVisible: !this.state.navItemsVisible });
  }

  render() {
    //const rootPath = `${__PATH_PREFIX__}/`
    //const blogPath = `${__PATH_PREFIX__}/blog/`

    return (
      <header data-header-fixed={this.state.isHeaderFixed} data-full-width-header={this.props.isFullWidthHeader}>
        <nav class="nav-header">
          <div class="nav-items-wrapper">
            <div class="nav-header-logo"></div>
            <div class="nav-header-title">Christian Konrad</div>
            <BurgerMenu onClick={this.toggleNavItems}
              isVisible={this.state.burgerMenuVisible}
              areNavItemsVisible={this.state.navItemsVisible} />
            <NavItems
              onNavItemClicked={this.handleNavItemClick}
              onCTAClicked={this.props.onCTAClicked}
              activeSection={this.props.activeSection}
              areVisible={this.state.navItemsVisible}
              items={this.props.items} />
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
