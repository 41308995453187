import React from "react"
import { Link } from "gatsby"

// TODO get per graphql
import githubLogo from "../../content/assets/social-icons/light/github.svg"
import fiverrLogo from "../../content/assets/social-icons/light/fiverr.svg"
import facebookLogo from "../../content/assets/social-icons/light/facebook.svg"
import linkedinLogo from "../../content/assets/social-icons/light/linkedin.svg"
import upworkLogo from "../../content/assets/social-icons/light/upwork.svg"

//import styles from "./footer.module.css"

//className={styles.user}

const LanguageItem = props =>
  <div class="language-item">
    <div class="language-item-category">{props.level}</div>
    <div class="language-item-value">
      <div class="language-name">{props.languageName}</div>
      <div class="language-abbr">{props.languageAbbrevation}</div>
    </div>
  </div>

const LanguageItems = props =>
  <div class="language-items">
    {props.languages.map(language =>
      <LanguageItem key={language.abbrevation} languageName={language.name} languageAbbrevation={language.abbrevation} level={language.level} />
    )}
  </div>

const FooterItem = props =>
  <div class="footer-item">{props.children}</div>

const FooterColumn = props =>
  <div class="footer-column" style={{
      flex: props.flex ? props.flex : 1
    }}>
    <div class="footer-column-title">{props.title}</div>
    {props.children}
  </div>

const FooterColumns = props =>
  <div class="footer-columns">
    {props.children}
  </div>

// TODO move to own component
const SectionContent = props =>
  <div class="section-content" data-aos={props.enableAOS === false ? '' : 'fade-right'}>
    {props.children}
  </div>

const SocialIcon = props =>
  <a class="social-icon" data-platform={props.platform} href={props.url} target="_blank"
    style={{backgroundImage: `url(${props.icon})`}}></a>

class Footer extends React.Component {
  render() {
    const { location, title, enableAOS, children } = this.props

    // TODO get content from elsewhere via graphql

    return (
      <footer data-section="footer">
				<SectionContent enableAOS={enableAOS}>

					<div className="footer-title">Did you like what you've seen?</div>
					<div class="footer-subtitle">Get in touch and let us find out what I can do for you.</div>

					<FooterColumns>
						<FooterColumn title="Contact me">
							<FooterItem>
                <a href="&#x6d;&#x61;&#x69;&#x6c;&#x74;&#x6f;&#x3a;&#x6f;&#x66;&#x66;&#x69;&#x63;&#x65;&#x40;&#x63;&#x68;&#x72;&#x69;&#x73;&#x74;&#x69;&#x61;&#x6e;&#x2d;&#x6b;&#x6f;&#x6e;&#x72;&#x61;&#x64;&#x2e;&#x6d;&#x65;">&#x6f;&#x66;&#x66;&#x69;&#x63;&#x65;&#x40;&#x63;&#x68;&#x72;&#x69;&#x73;&#x74;&#x69;&#x61;&#x6e;&#x2d;&#x6b;&#x6f;&#x6e;&#x72;&#x61;&#x64;&#x2e;&#x6d;&#x65;</a>
              </FooterItem>
            </FooterColumn>
            <FooterColumn title="I will talk to you in">
							<FooterItem>
                <LanguageItems languages={[
                  { name: 'English', abbrevation: 'en_US', level: 'Fluent' },
                  { name: 'Deutsch', abbrevation: 'de_DE', level: 'Native' },
                  { name: 'Espanõl', abbrevation: 'es_ES', level: 'Beginner' }
                ]} />
							</FooterItem>
						</FooterColumn>
						<FooterColumn title="Find me here" flex="0.5">
							<FooterItem>
                <SocialIcon platform="github" url="https://github.com/christian-konrad" icon={githubLogo} />
                <SocialIcon platform="linkedIn" url="https://www.linkedin.com/in/konradchristian" icon={linkedinLogo}/>
                {/* <SocialIcon platform="facebook" url="https://www.facebook.com/ckonrad.aviate" icon={facebookLogo}/> */}
                {/* <SocialIcon platform="upwork" url="https://www.upwork.com/freelancers/~01902261611942f7c7" icon={upworkLogo}/> */}
                <SocialIcon platform="fiverr" url="https://www.fiverr.com/chris_konrad" icon={fiverrLogo}/>
							</FooterItem>
						</FooterColumn>
					</FooterColumns>
					<div class="footer-bottom-bar">
						<div class="footer-made-with">Built with <a class="react-link" href="https://reactjs.org/" target="_blank">REACT</a> and <a class="gatsby-link" href="https://www.gatsbyjs.org/" target="_blank"></a> • Image credits to <a href="photos.icons8.com">photos.icons8.com</a></div>
						<div class="footer-copyright">© 2024 Christian Konrad • <Link to="/imprint">Imprint</Link></div>
					</div>
				</SectionContent>
			</footer>
    )
  }
}

export default Footer
