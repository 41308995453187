import React from "react"
import { Link } from "gatsby"

const SectionNavItem = props =>
  <div class="nav-item"
    data-section={props.section}
    data-page={props.page}
    data-active={props.active}
    onClick={() => props.onNavItemClicked ? props.onNavItemClicked(props.section) : null}
  >{props.children}</div>

const LinkNavItem = props =>
  <Link to={props.page}>
    <SectionNavItem
      key={props.key}
      page={props.page}
    >
      {props.children}
    </SectionNavItem>
  </Link>  

const NavItem = props =>
  props.page 
    ? <LinkNavItem 
        key={props.key}
        page={props.page}
      >
        {props.children}
      </LinkNavItem>
    : <SectionNavItem 
        key={props.section}
        section={props.section}
        page={props.page}
        onNavItemClicked={props.onNavItemClicked}
        active={props.active}
      >
        {props.children}
      </SectionNavItem>

export default NavItem
